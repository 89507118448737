import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import {Deutsch, English, Type, LinkWORK, LinkHOME,  LinkDE, LinkEN, Page, SiteTitle} from '../components/linksetc.js'
import Typist from 'react-typist'

const AboutPage = ( {location} ) => (




  <Layout>


    <SiteTitle string="Services" />


      <Page location={location}>


          <English Language={location.search}>
                I offer copywriting, which is a discipline of marketing with a conceptual and language-based approach.<br/>
                Or: I think and write to sell your product. In English and <LinkDE location={location}>German</LinkDE>.
                <p/><p/>


                Digital concepts and texts for websites and apps:
                <ul>
                    <li>site structure</li>
                    <li>wireframes</li>
                    <li>SEO</li>
                    <li>UX writing</li>
                    <li>content</li>
                </ul>

                <p/>
                Long copy (=longer texts):
                <ul>
                    <li>articles</li>
                    <li>blogs</li>
                    <li>pillar pages</li>
                    <li>short stories</li>
                    <li>interviews</li>
                </ul>

                <p/>
                Copywriting for brandings:
                <ul>
                    <li>verbal identity</li>
                    <li>brandbook copy</li>
                    <li>brand identity</li>
                    <li>naming</li>
                </ul>
                
                <p/>
                General copywriting:
                <ul>
                    <li>headlines</li>
                    <li>claims</li>
                    <li>short copy</li>
                </ul>

                <p/>
                Advertising campaigns:
                <ul>
                    <li>creative strategy</li>
                    <li>creative idea</li>
                    <li>execution</li>
                </ul>

                <p/>Check my <LinkWORK location={location}>work</LinkWORK> for concrete examples. Or go <LinkHOME location={location}>home</LinkHOME>.

          </English>





         <Deutsch Language={location.search}>
                Ich biete Copywriting an. Dies ist eine Marketingdisziplin mit konzeptionellem Fokus auf Sprache.<br/>
                Oder: Ich denke und schreibe, um deine Produkte zu verkaufen. Auf Deutsch und <LinkEN location={location}>Englisch</LinkEN>.
                <p/>
                <p/>


                Digitale Konzepte und Texte für Webseiten und Apps:
                <ul>
                    <li><b>Seitenstruktur</b> - logischer Aufbau basierend auf Produkt, Zielgruppe und Strategie</li>
                    <li><b>Wireframes</b> - abtrakte Darstellung für direkte Umsetzung ins Design</li>
                    <li><b>SEO Optimierung</b> - SEO-Strategie, Keyword Recherche, Einbau in Text</li>
                    <li><b>UX writing</b> - Interaktion mit Usern sprachlich leiten</li>
                    <li><b>Content</b> - Webseitentext optimiert für Menschen und Suchmaschinen</li>
                </ul>


                <p/><p/>
                Long copy (=Lange Texte):
                <ul>
                    <li>Artikel</li>
                    <li>Blogs</li>
                    <li>Pillar Pages</li>
                    <li>Kurzgeschichten</li>
                    <li>Interviews</li>
                </ul>


                <p/><p/>
                Copywriting für Branding:
                <ul>
                    <li><b>Verbale Identität</b> - eine Sprache passend zu ihrer Marke definieren</li>
                    <li><b>Brandbook Text</b> - logische Herleitung des Designs</li>
                    <li><b>Brand Identität</b> -  Ausformulierung der Markenstrategie, Mission, Vision, USP</li>
                    <li><b>Naming</b> - Namensfindung für Produkte, Dienstleistungen und Marken</li>
                </ul>
                

                <p/><p/>
                Klassisches Texten / Werbetexte:
                <ul>
                    <li>Headlines & slogans</li>
                    <li>Namings</li>
                    <li>Claims</li>
                    <li>Kurztexte/microcopy</li>
                </ul>

                <p/><p/>
                Werbekampagnen:
                <ul>
                    <li><b>Kreative Strategie</b> - aufbauend auf Briefing, Zielgruppenanalyse, Analytics des Marketingteams</li>
                    <li><b>Kreative Idee</b> - alleine oder in Zusammenarbeit mit Kreativteam & Beratern</li>
                    <li><b>Umsetzung</b> - des textlichen Teils</li>
                </ul>

                <p/><LinkWORK location={location}>Mein Portfolio</LinkWORK> für konkrete Beispiele, oder <LinkHOME location={location}>zurück zum Anfang</LinkHOME>.
        </Deutsch>



      </Page>

  </Layout>


)

export default AboutPage
